import './App.css';

function App() {
  const donationLinks = [
    {
      title: 'AKUT WhatsApp',
      url: 'https://wa.me/905493014706',
    },
    {
      title: 'AFAD',
      url: 'https://www.afad.gov.tr/depremkampanyasi2',
    },
    {
      title: 'KIZILAY',
      url: 'https://www.kizilay.org.tr/Bagis/BagisYap/404/pazarcik-depremi-bagisi',
    },
    {
      title: 'AHBAP',
      url: 'https://ahbap.org/bagisci-ol',
    },
    {
      title: 'AHBAP Kripto',
      url: 'https://twitter.com/ahbap/status/1622962238372937730',
    },
    {
      title: 'Trendyol',
      url: 'https://www.trendyol.com/sr?cid=619322'
    },
    {
      title: 'Hepsiburada',
      url: 'https://www.hepsiburada.com/deprem-seferberligi'
    },
    {
      title: 'Getir',
      url: 'https://media.discordapp.net/attachments/831980985967968317/1072887158949560350/ed3afa59-3429-4509-a15d-4b65addb418d.png?width=640&height=1138'
    },
    {
      title: 'Yemeksepeti',
      url: 'https://www.yemeksepeti.com/shop/w88l/yardim-marketi'
    }
  ];

  const mapLinks = [
    {
      title: 'ITU Enkaz Dinleme Uygulaması',
      subtitle: 'ITU öğrencileri tarafından geliştirilen enkaz altında kalan kişilerin seslerini dinleyebildiğiniz uygulama.',
      url: 'https://web.itu.edu.tr/sariero/dinleme.html?fbclid=PAAaYD0M97iY8lFhFpuOuBBCyAerinap2rpzoY1rNvUJWPFTEIJL6q2X4HBhw',
      backgroundColor: 'bg-red-50',
      color: 'text-red-600',
    },
    {
      title: 'afetharita.com',
      subtitle: 'Sosyal medyada paylaşılmış ihbarları harita üzerinde gösteren web sitesi.',
      url: 'https://afetharita.com/',
      backgroundColor: 'bg-yellow-50',
      color: 'text-yellow-600',
    },
    {
      title: 'afetbilgi.com',
      subtitle: 'Barınma ve toplanma alanları, bağış yardım noktaları, önemli telefonlar gibi hayati bilgilerini derleyen web sitesi.',
      url: 'https://afetbilgi.com/',
      backgroundColor: 'bg-green-50',
      color: 'text-green-600',
    },
    {
      title: 'afetbarinma.com',
      subtitle: 'Gençlik ve Spor Bakanlığı\'nın afetzedeler için açtığı barınma alanlarını gösteren web sitesi.',
      url: 'http://afetbarinma.com/',
      backgroundColor: 'bg-purple-50',
      color: 'text-purple-600',
    },
    {
      title: 'kurtar.me',
      subtitle: 'Deprem bölgelerindeki güvenli destek alanlarının ve acil ihtiyaç taleplerinin herkese açık şekilde görüntülenebilmesi için web sitesi',
      url: 'http://kurtar.me/',
      backgroundColor: 'bg-green-50',
      color: 'text-green-600',
    },
    {
      title: 'depremkayipilan.com',
      subtitle: 'Kayıp ilanları ve durumlarını tek bir platformda toplayan web sitesi.',
      url: 'https://depremkayipilan.com/',
      backgroundColor: 'bg-blue-50',
      color: 'text-blue-600',
    },
    {
      title: 'depremyardim.com',
      subtitle: 'Yardıma ihtiyacı olan depremzedelerin adreslerinin ayrıntılı bir şekilde girilebilen web sitesi.',
      url: 'https://depremyardim.com/',
      backgroundColor: 'bg-red-50',
      color: 'text-red-600',
    },
    {
      title: 'yakinimibul.net',
      subtitle: 'Ailesinden haber alamayanların kontrol edebileceği listeleri oluşturan web sitesi.',
      url: 'https://yakinimibul.net/',
      backgroundColor: 'bg-purple-50',
      color: 'text-purple-600',
    },
    {
      title: 'deprem.io',
      subtitle: 'Enkaz altında kalan kişilerin bilgilerini girebildiği ve bu bilgilerin dernek ve kurumlarla paylaşıldığı web site.',
      url: 'https://deprem.io/',
      backgroundColor: 'bg-orange-50',
      color: 'text-orange-600',
    },
    {
      title: 'stream.epctex.com',
      subtitle: 'Enkaz altında kalanların bildirildiği twitleri tek yerden takip etmenizi sağlayan web sitesi.',
      url: 'https://stream.epctex.com/',
      backgroundColor: 'bg-pink-50',
      color: 'text-pink-600',
    },
    {
      title: 'deprem.basarsoft.com.tr',
      subtitle: 'Tüm yardım taleplerini gösteren ve talep girebileceğiniz web adresi.',
      url: 'https://deprem.basarsoft.com.tr/',
      backgroundColor: 'bg-yellow-50',
      color: 'text-yellow-600',
    },
    {
      title: 'depremihtiyac.com.tr',
      subtitle: 'Deprem bölgesindeki yardımları tek bir platformda toplayan web site.',
      url: 'https://deprem.basarsoft.com.tr/',
      backgroundColor: 'bg-green-50',
      color: 'text-green-600',
    },
    {
      title: 'yikilanbinalar.com.tr',
      subtitle: 'Yıkılan binaların adres bilgilerinin ve durumlarının girildiği web site.',
      url: 'https://yikilanbinalar.com.tr/',
      backgroundColor: 'bg-blue-50',
      color: 'text-blue-600',
    },
    {
      title: 'enkazbildirim.org',
      subtitle: 'Türkiye\'nin güncel enkaz haritasını gösteren web sitesi',
      url: 'https://enkazbildirim.org/',
      backgroundColor: 'bg-red-50',
      color: 'text-red-600',
    },
    {
      title: 'Cimer Deprem Acil',
      subtitle: '',
      url: 'https://twitter.com/iletisim/status/1622477229782335490?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1622477229782335490%7Ctwgr%5Ec3f471b72e9e0f03598c41e0a26524f43defabe0%7Ctwcon%5Es1_&ref_url=https%3A%2F%2Fwww.aa.com.tr%2Ftr%2Fgundem%2Fcimerde-deprem-acil-basligi-kullanima-hazir%2F2807410',
      backgroundColor: 'bg-purple-50',
      color: 'text-purple-600',
    },
  ];

  return (
    <main>
      <header className='bg-blue-600 p-8'>
        <h1 className='text-white text-2xl font-semibold'>
          AFETCOZUM
        </h1>

        <h2 className='text-white text-sm mt-1'>
          Depreme yardım ve destek amaçlı oluşturulan siteleri, banka hesaplarını, kurum ve kuruluşları tek bir yerde toplayan yönlendirme platformudur. Gönüllülük esasıyla yapılmıştır.
        </h2>

        <h2 className='text-white text-sm mt-1'>
          info@afetcozum.com
        </h2>
      </header>

      <section className='pt-6 pl-6 pr-6'>
        <h2 className='font-semibold mb-4 text-gray-400'>
          BAĞIŞ İÇİN RESMI KURUMLAR YÖNLENDİRME LINKLERI
        </h2>

        <ul className='flex flex-wrap gap-x-2'>
          {
            donationLinks.map((link) => (
              <a
                href={link.url}
                target='_blank'
                rel='noreferrer'
                className='bg-blue-50 hover:bg-blue-100 px-4 py-2 rounded flex flex-row gap-x-2 mt-2'>

                <span className='text-blue-600'>{link.title}</span>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0598 10.9399C15.3098 13.1899 15.3098 16.8299 13.0598 19.0699C10.8098 21.3099 7.16985 21.3199 4.92985 19.0699C2.68985 16.8199 2.67985 13.1799 4.92985 10.9399" stroke="#0F43FB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.9707 3.16992C16.4607 3.19992 17.9407 3.78992 19.0707 4.92992C21.4107 7.26992 21.4107 11.0699 19.0707 13.4199" stroke="#0F43FB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10.5909 13.4099C8.25094 11.0699 8.25094 7.26992 10.5909 4.91992" stroke="#0F43FB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

              </a>
            ))
          }
        </ul>
      </section>

      <section className='pl-6 pr-6 pt-6 pb-20'>
        <h2 className='font-semibold mb-4 text-gray-400'>
          YARDIM VE DESTEK SİTELERİ
        </h2>
        {
          mapLinks.map((link) => (
            <a
              href={link.url}
              target='_blank'
              rel='noreferrer'
              className={
                `px-3 py-3 rounded-xl  flex flex-row items-center
                mt-2 hover:saturate-150 ${link.backgroundColor} ${link.color}`
              }>

              <svg className='hidden' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.96 6.17C18.96 7.56 20.34 9.77 20.62 12.32" stroke="#838383" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.49 12.37C3.75 9.83 5.11 7.62 7.09 6.22" stroke="#838383" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.19 20.94C9.35 21.53 10.67 21.86 12.06 21.86C13.4 21.86 14.66 21.56 15.79 21.01" stroke="#838383" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.06 7.7C13.5954 7.7 14.84 6.45535 14.84 4.92C14.84 3.38465 13.5954 2.14 12.06 2.14C10.5246 2.14 9.28 3.38465 9.28 4.92C9.28 6.45535 10.5246 7.7 12.06 7.7Z" stroke="#838383" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.83 19.92C6.36535 19.92 7.61 18.6754 7.61 17.14C7.61 15.6046 6.36535 14.36 4.83 14.36C3.29465 14.36 2.05 15.6046 2.05 17.14C2.05 18.6754 3.29465 19.92 4.83 19.92Z" stroke="#838383" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.17 19.92C20.7054 19.92 21.95 18.6754 21.95 17.14C21.95 15.6046 20.7054 14.36 19.17 14.36C17.6346 14.36 16.39 15.6046 16.39 17.14C16.39 18.6754 17.6346 19.92 19.17 19.92Z" stroke="#838383" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <div className='flex flex-col ml-3'>
                <span className='font-medium'>{link.title}</span>
                <span className='text-gray-500 max-w-xl'> {link.subtitle}</span>
              </div>

              <span className='ml-auto' />
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.91 19.92L15.43 13.4C16.2 12.63 16.2 11.37 15.43 10.6L8.91 4.08" stroke="#000000" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </a>
          ))
        }
      </section>
    </main>
  );
}

export default App;
